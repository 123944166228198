@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.about-card {
    @include card;
    margin: 5rem 2.5rem 2rem 2.5rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 0 2rem;

    &__header {
        @include mobileH2;
        padding-bottom: 0.5rem;
    }

    &__subhead {
        @include mobileH3;
        padding-bottom: 0.5rem;
    }

    &__stack {
        display: flex;
        flex-direction: column;
    }

    &__body {
        margin: 1 rem;
    }
}

.vearthy {
    font-weight: bold;
    text-decoration: underline;
    color: $primary;

    &:hover {
        color: $secondary;
    }
}

.card-button {
    padding: 0.5rem 0.5rem;
    font-weight: bold;
    text-align: end;
}

.card-img {
    width: 50%;
}
