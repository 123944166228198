@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.footer {
    display: flex;
    flex-direction: column;
    background-color: $primary;
    padding: 1rem;

    @include tablet {
        flex-direction: row;
        justify-content: center;
    }

    &__right-linksright {
        display: flex;
        flex-direction: column;
    }
}

.footer-left {
    @include tablet {
        width: 40%;
        border-right: 1px solid $white;
    }

    &__logo {
        padding: 0.5rem;
        height: 40px;
    }

    &__text {
        margin: 1rem 0;
        width: 75%;
        color: $white;
        padding-left: 1rem;
        @include mobileP2;
    }

    &__body {
        padding-top: 0.5rem;
    }
}

.footer-icons {
    display: flex;
    padding-left: 1rem;

    &__copyright {
        @include mobileP2;
        padding: 1rem;
        color: $white;
    }
}

.footer-right {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;

    @include tablet {
        // width: 40%;
        margin-left: 5rem;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        padding-left: 1rem;
    }

    &__text {
        font-size: 1rem;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
        padding-bottom: 1rem;
    }

    &__heading-box {
        border-radius: $buttonRadius;
        padding: 0.5rem;
        width: 130px;
        @include mobileP2;
        color: $secondary;

        @include tablet {
            width: 290px;
        }
    }

    &__heading-button {
        color: $white;
        background-color: $secondary;
        border-radius: $buttonRadius;
        padding: 0.5rem 2rem 0.5rem 2rem;
        margin-left: 0.5rem;
        @include mobileP2;

        &:hover {
            outline: 1px solid $white;
        }

        @include tablet {
            margin-left: 0;
            margin-top: 0.5rem;
        }

        @include desktop {
            margin-left: 1.5rem;
        }
    }

    &__contact {
        display: flex;
        flex-flow: row nowrap;
        color: $white;

        @include tablet {
            justify-content: flex-end;
            margin-right: 3rem;
            padding-top: 1rem;
        }
    }

    &__linksleft {
        display: flex;
        flex-direction: column;

        @include tablet {
            align-items: flex-end;
            flex-direction: row;
        }
    }

    &__content-left {
        padding: 0.25rem 1rem;
        @include mobileP1;
    }
}
