@use "./variables" as *;

@mixin tablet {
    @media (min-width: $tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: $desktop-breakpoint) {
        @content;
    }
}

@mixin mobileH1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
}

@mixin mobileH2 {
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
}

@mixin mobileH3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.1rem;
}

@mixin mobileH4 {
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 400;
}

@mixin mobileP1 {
    font-size: 0.9375rem;
    line-height: 1.625rem;
    font-weight: 400;
}

@mixin mobileP2 {
    font-size: 0.8125rem;
    line-height: 1.25rem;
    font-weight: 400;
}

@mixin mobileHamburg {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.25rem;
}

@mixin desktopH1 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 600;
}

@mixin desktopH2 {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
}

@mixin desktopH3 {
    font-size: 1rem;
    line-height: 1.125rem;
    font-weight: 400;
}

@mixin desktopH4 {
    font-size: 1.5rem;
    line-height: 1.125rem;
    font-weight: 400;
}

@mixin desktopP1 {
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
}

@mixin desktopP2 {
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 400;
}

@mixin desktopP3 {
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;
}

@mixin card {
    border-radius: $cardRadius;
    box-shadow: $shadow;
    background: linear-gradient(
        90deg,
        rgba(246, 246, 246, 1) 0%,
        rgba(255, 255, 255, 1) 35%,
        rgba(223, 223, 223, 1) 100%
    );
}
