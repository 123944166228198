$desktop-breakpoint: 1280px;
$tablet-breakpoint: 768px;

$buttonRadius: 100px;
$cardRadius: 10px;

$primary: #1c292c;
$secondary: #38474a;
$third: #4d009f;

$black: #242424;
$white: #ffffff;
$hover: rgb(218, 218, 218);

$shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
