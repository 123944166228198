@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.navbar {
    @media only screen and (max-width: $tablet-breakpoint) {
        display: none;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    animation-name: slideDown;
    animation-duration: 1s;
    animation-fill-mode: forwards; /* Keeps the header in view after the animation */
}
@keyframes slideDown {
    from {
        top: -100px;
    }
    to {
        top: 0;
    }
}

.leftside {
    display: flex;

    &__logo {
        margin: 1rem;

        @include tablet {
            height: 75px;
        }
    }
}
.rightside {
    display: flex;

    &__list-parent {
        display: flex;
    }

    &__list-item {
        @include mobileH2;
        margin: 0 1rem 2rem 1rem;
        padding-bottom: 0.25rem;
        display: inline-block;
        text-decoration: none;
        text-wrap: nowrap;
        color: $white;
        position: relative;

        &:hover {
            color: $hover;
            cursor: pointer;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 2px;
            bottom: 0;
            left: 0;
            background: linear-gradient(to left, #344030, #98a194),
                linear-gradient(to left, #344030, #d4ddd2, #aabba4);
            transform: scaleX(0);
            transform-origin: top left;
            transition: transform 0.3s ease;
        }
        &:hover::before {
            transform: scaleX(1);
        }
    }
}
