@use "./variables" as *;
@use "./mixins" as *;
@use "./typography" as *;

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: Geneva, Tahoma, sans-serif;
}

ol,
ul,
menu {
    list-style: none;
}

a {
    text-decoration: inherit;
    color: inherit;
}

fieldset,
button,
input[type="button"] {
    border: none;
}

input,
textarea,
select,
button {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

button,
input[type="button"] {
    background-color: initial;
    cursor: pointer;
    border: initial;
}
