@use "./style/global" as *;
@use "./style/mixins" as *;
@use "./style/typography" as *;
@use "./style/variables" as *;

.App {
    background-image: url("./assets/herobackground.png");
    background-position: center;
    background-size: cover;
    height: 1030px;
    position: absolute;
    width: 100%;
}

.top-button {
    height: 30px;
    position: fixed;
    top: 93%;
    right: 2%;
    opacity: 50%;

    @include tablet {
        top: 89%;
    }

    &:hover {
        opacity: 100%;
    }
}
