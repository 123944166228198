@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.contact-container {
    background-image: url("../../assets/sectionhalf.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;

    &__header {
        margin: 2rem;
        @include mobileH1;

        @include desktop {
            @include card;
            padding: 1rem 4rem;
        }
    }

    &__button-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__button1,
    &__button2 {
        width: 70%;
        height: 50px;
        margin: 0.5rem;
        border-radius: $buttonRadius;
        background: linear-gradient(90deg, rgba(41, 82, 112, 1) 0%, rgba(82, 65, 117, 1) 100%);
        color: white;

        &:hover {
            scale: 1.1;
            outline: 1px solid rgba(82, 65, 117, 1);
        }
    }
}

.contact-card {
    @include card;
    padding: 2rem;
    height: 100%;
    width: 80%;

    &__body {
        @include mobileP1;
        padding: 1rem 0;
    }

    &__subhead {
        @include mobileH2;
    }
}
