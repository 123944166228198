@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.tec-container {
    margin-top: 6rem;
    padding-bottom: 2rem;
    background: linear-gradient(90deg, rgba(28, 41, 44, 1) 0%, rgba(56, 71, 74, 1) 100%);

    &__header {
        padding: 2rem 0 0 2rem;
        padding-left: 2rem;
        color: $white;
        @include mobileH3;
    }

    &__subhead {
        margin: 2rem;
        color: $white;
        @include mobileH1;
    }
}

.tec-card {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    justify-content: center;

    &__icon {
        box-shadow: $shadow;
        width: 30%;
        max-width: 170px;
        padding: 1rem;
        margin: 1rem;
        border-radius: $cardRadius;

        &:hover {
            box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(226, 226, 226, 0.19);
        }
    }
}
