@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.burger-container {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    text-align: right;

    @include tablet {
        display: none;
    }
}

@keyframes slide-in-top {
    0% {
        -webkit-transform: translateY(-1000px);
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

.burger-left {
    display: flex;

    &__logo {
        margin: 1rem;
        height: 75px;
    }
}

.burger-menu {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 2rem;
    position: fixed;
    top: 0;
    right: 0;

    @include tablet {
        display: none;
    }

    &__bar {
        height: 3px;
        width: 100%;
        background-color: $white;
    }
}

.burger-nav {
    cursor: pointer;
    position: fixed;
    width: 100%;
    height: 100svh;
    background: linear-gradient(120deg, rgba(28, 41, 44, 1) 0%, rgba(56, 71, 74, 1) 100%);
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

    &__link {
        padding: 0.25rem 1rem;
        margin-bottom: 1rem;
        color: $white;
        transition: color 0.2s;
        background: linear-gradient(to right, #344030, #98a194),
            linear-gradient(to right, #344030, #d4ddd2, #aabba4);
        background-size: 100% 1.5px, 0 1.5px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;

        &:hover {
            background-size: 0 1.5px, 100% 1.5px;
        }
    }
}
