@use "../../style/global" as *;
@use "../../style/mixins" as *;
@use "../../style/variables" as *;

.hero-container {
    display: flex;
    flex-direction: column;

    &__icon1,
    &__icon2 {
        margin-top: 0.25rem;
        height: 25px;
        filter: invert(100%);

        @include tablet {
            margin-top: 1rem;
        }
    }

    &__icon1 {
        margin-left: 2rem;
    }

    &__icon2 {
        margin-left: 0.5rem;
    }
}

video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.hero-text {
    width: 75%;

    &__header {
        @include mobileH1;
        color: $white;
        margin: 1rem 2rem;
    }

    &__subhead {
        @include mobileH2;
        color: $white;
        margin: 1rem 2rem;
    }

    &__body {
        @include mobileP1;
        color: $white;
        margin-left: 2rem;
        padding-top: 0.5rem;

        @include tablet {
            width: 70%;
        }
    }
}
